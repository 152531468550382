body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #2f3a4f;
	font-size: 22px;
}

h1 {
	margin: 0;
	font-size: 36px;
}

section {
	max-width: 800px;
	width: 100%;
	margin: auto;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 50px;
	margin-bottom: 50px;
}

/* win image code */
.gif-win {
	width: 100%;
	height: 100%;
}

.gif-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.gif-overlay img {
	display: block;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}


.center {
	text-align: center;
}

.displayNone {
	display: none
}


.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex1 {
	flex-grow: 1;
}

.pointer {
	cursor: pointer;
	/*display: flex;*/
	justify-content: center;
	align-items: center;
	/*border: 2px solid black;*/
	padding: 20px;
	border: 4px solid transparent;
	background-clip: padding-box;
	background-image: linear-gradient(to right, #6bbade, #2f3a4f);
	outline: 3px solid #000;
}

.pad {
	padding: 1em;
}

.pad-sm {
	padding: .5em;
	flex: 1;
	margin-right: 10px;
	width: 100%;
	font-size: 28px;
	
}

.start-button {
	flex: 1;
	display: flex;
	align-items: flex-end;
	margin-top: 10px;
	
  }  

.gap {
	gap: 1em;
}

.title {
	font-size: 36px;
	font-weight: bold;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 40px;
}

.menu {
	display: flex;
}

.container {
	/*overflow-x: auto;*/
	/*white-space: nowrap;*/
}

.hide-scrollbar {
	/*overflow: hidden !important;*/
}

.screen-width {
	display: grid;
	grid-template-columns: 1fr;
	max-width: 97%;
	box-sizing: border-box;
	word-wrap: break-word;
}

.video-wrapper {
	position: relative;
	max-width: 785px;
	max-height: 315px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: "100%";
  }

  .video-wrapper iframe {
	width: 80%;
	height: 40vh;
	max-height: 385px ;
  }
  