.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /*text-align: center;*/
  margin: 2rem;
  max-width: 800px;
  gap: 5rem;
}

.container>div {
  flex-grow: 1;
}

.logo {
  margin-right: 10px;
  width: 75px;
  height: 75px;
}


.container>div {
  opacity: 0;
  transform: translateY(20px);
  animation: container-item 0.5s ease-in-out forwards;
}

.container>div:nth-child(1) {
  animation-delay: 0.1s;
}

.container>div:nth-child(2) {
  animation-delay: 0.2s;
}

.container>div:nth-child(3) {
  animation-delay: 0.3s;
}

.container>div:nth-child(4) {
  animation-delay: 0.4s;
}

.container>div:nth-child(5) {
  animation-delay: 0.5s;
}

.container>div:nth-child(6) {
  animation-delay: 0.6s;
}

.container>div:nth-child(7) {
  animation-delay: 0.7s;
}

.container>div:nth-child(8) {
  animation-delay: 0.8s;
}

.container>div:nth-child(9) {
  animation-delay: 0.9s;
}

.container>div:nth-child(10) {
  animation-delay: 1s;
}
.container>div:nth-child(11) {
  animation-delay: 1.1s;
}

@keyframes container-item {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

a {
  position: relative;
  display: inline-block;
}

a span {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

a:hover span {
  display: block;
}

.container {
  /* styles for container */
}

.description {
  margin-top: 20px;
  text-align: justify;
  border-top: 1px solid black;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.description .centered-text {
  line-height: 2em;
  max-width: 500px;
}

.code-ref .logo {
  width: 30px;
  height: 30px;
}

.vertical-center {
  display: flex;
  align-items: center;
}
  