table {
  border-collapse: collapse;
  margin: auto;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  /*border: none;*/
}
td {
  /*border: 1px solid black;*/
  height: 100px;
  width: 100px;
  text-align: center;
  font-size: 72px;
  font-weight: bold;
  cursor: pointer;
}
td:hover {
  background-color: #EEE;
}
#winner {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.vertical-line {
  border-left: 3px solid black;
}

.horizontal-line {
  border-bottom: 3px solid black;
}