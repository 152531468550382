table {
  border-collapse: collapse;
  margin: auto;
  
}

.td-one {
  width: 100px;
  height: 100px;
  border: 1px solid black;
  border-width: 3px;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  cursor: pointer;
}
