.profileImage {
  max-width: 200px;
  width: 20vw;
  border-radius: 50%;
  border: .75vw solid rgb(0, 0, 0);
}

.largePlus {
  font-size: 3em
}

.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center
}